<template>
  <div>
    <p class="h4 pt-4 pb-4">招募公告 > 线上公告</p>
    <el-button type="primary" class="mb-4" @click="routerTo('/tips-admin/recruit/add')">添加公告</el-button>
    <el-table
      :data="lists"
      border
      style="width: 100%"
      @expand-change="handleExpandChange">
      <el-table-column label="序号" prop="id"></el-table-column>
      <el-table-column label="项目名称" prop="name"></el-table-column>
      <el-table-column label="发布人" prop="creater"></el-table-column>
      <el-table-column label="发布时间" width="180">
        <template slot-scope="scope">
          <span>{{ formatTime(scope.row.created) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="routerTo(`/tips-admin/recruit/edit?id=${scope.row.id}`)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">下线</el-button>
        </template>
      </el-table-column>
      <!-- <el-table-column label="修改状态" prop="status" width="120">
        <template slot-scope="scope">
          <el-select :value="scope.row.status" size="mini" @change="e => changeStatus(scope.row, e, scope.$index)">
            <el-option label="无效项目" :value="1"></el-option>
            <el-option label="正在招募" :value="2"></el-option>
            <el-option label="招募已满" :value="3"></el-option>
          </el-select>
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
import { recruitsList, updateRcrStatus } from '@/api/tips'
import { formatTime } from '@/lib/format'
export default {
  name: 'common',
  inject: ['reload'],
  data () {
    return {
      lists: [],
      formatTime: formatTime
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.getLists()
    })
  },
  methods: {
    /**
     * 跳转路由，添加与编辑分情况
     */
    routerTo (val) {
      this.$router.push({
        path: val
      })
    },
    handleDelete (index, tip) {
      this.$confirm('确定将此招募项目下线？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        updateRcrStatus(tip.id, 9).then(
          () => {
            this.$message({
              type: 'success',
              message: '下线成功!'
            })
            this.reload()
          }
        )
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    getLists () {
      recruitsList().then(
        ({ data = {} }) => {
          this.lists = data.items.filter(v => v.status !== 9)
        }
      )
    },
    changeStatus (e, status, index) {
      updateRcrStatus(e.id, status).then(
        res => {
          if (res.code === 0) {
            this.$notify({
              type: 'success',
              title: '状态修改成功',
              message: res.message
            })
            this.lists[index].status = status
          } else {
            this.$notify({
              type: 'error',
              title: '状态修改失败',
              message: res.message
            })
          }
        }
      )
    }
  }
}
</script>
<style>
.bg-sm-blue {
  background: #ecf5ff !important;
  color: #409eff;
}
</style>
